.head-text {
   position: relative;
}
.text-on-image {
  position: absolute;
  right: 25%;
  left: 25%;
  bottom: 15%;
}
.fill-window {
    height: 100%;
    left: 0;
    width: 100%;
    overflow: hidden;
}
