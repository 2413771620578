.navBarLink {
  margin: 5px;
}

.color-nav {
  background-color: lightgray
}

.vertical-center {
  align-items: center
}
