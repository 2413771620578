.Body {
  margin-top: 90px;
}

.Logo img {
  object-fit: contain;
  border-style: solid;
  border-width: thin;
  padding: 5px;
  float: left;
}

.Title {
  float: left;
  padding-left: 10px;
  padding-top: 6px;
}

.Share {
  text-align: right;
}
