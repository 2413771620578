@import url(https://fonts.googleapis.com/css2?family=Comfortaa:wght@300&display=swap);
body {
  font-family: 'Comfortaa', cursive;
}

main {
  min-height: 80vh;
}

.navBarLink {
  margin: 5px;
}

.color-nav {
  background-color: lightgray
}

.vertical-center {
  align-items: center
}

.head-text {
   position: relative;
}
.text-on-image {
  position: absolute;
  right: 25%;
  left: 25%;
  bottom: 15%;
}
.fill-window {
    height: 100%;
    left: 0;
    width: 100%;
    overflow: hidden;
}

.CarouselImg img {
  width: 100%;
  height: 350px;
  max-height: 350px;
  object-fit: cover;
}

.Body {
  margin-top: 90px;
}

.Logo img {
  object-fit: contain;
  border-style: solid;
  border-width: thin;
  padding: 5px;
  float: left;
}

.Title {
  float: left;
  padding-left: 10px;
  padding-top: 6px;
}

.Share {
  text-align: right;
}

.search-location-input {
  display: flex;
  width: 484px;
  border-radius: 24px;
  border: 1px solid #d8d8d8;
}

.search-location-input:focus-within {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-color: rgba(223,225,229,0);
  box-shadow: 0 1px 6px 0 rgba(32,33,36,0.28);
}

.search-location-input:hover {
  box-shadow: 0 1px 6px 0 rgba(32,33,36,0.28);
  border-color: rgba(223,225,229,0);
}

.search-location-input input {
  height: 32px;
  width: 100%;
  padding: 8px 12px;
  border: none;
  font-size: 16px;
  border-radius: 24px;
}

.search-location-input input:focus {
  outline: none;
}

.text {
   font-size: 13px;
   overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 8; /* number of lines to show */
   -webkit-box-orient: vertical;
}
.mobile-text {
   font-size: 12px;
   overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 4; /* number of lines to show */
   -webkit-box-orient: vertical;
}

