.search-location-input {
  display: flex;
  width: 484px;
  border-radius: 24px;
  border: 1px solid #d8d8d8;
}

.search-location-input:focus-within {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-color: rgba(223,225,229,0);
  box-shadow: 0 1px 6px 0 rgba(32,33,36,0.28);
}

.search-location-input:hover {
  box-shadow: 0 1px 6px 0 rgba(32,33,36,0.28);
  border-color: rgba(223,225,229,0);
}

.search-location-input input {
  height: 32px;
  width: 100%;
  padding: 8px 12px;
  border: none;
  font-size: 16px;
  border-radius: 24px;
}

.search-location-input input:focus {
  outline: none;
}
